body {
  margin: 0px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbcbcb;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// #root {
//     width: 100%;
//     height: 100%;
//     font-size: 70%;
// }

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-100vw {
  width: 100vw;
}

.h-100vh {
  height: 100vh;
}

.must-fill-fields {
  color: red;
}

.MuiAutocomplete-inputRoot {
  padding: 1px !important;
}

// .MuiTableContainer-root.css-gmvvtf-MuiTableContainer-root {
//   max-height: 400px !important;
// }

// .MuiTableContainer-root.css-1l4k7yp-MuiTableContainer-root {
//   max-height: clamp(350px, calc(100vh - 230px), 600px) !important;
// }