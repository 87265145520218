.candidates-section {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .candidates-section-data {
    width: 100%;
    height: calc(100vh - 160px);
  }

}

.create-candidates-section {
  .create-candidates {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    .headingPlusBackBtn {
      display: flex;
      // border: 1px solid red;
      align-items: center;
      position: relative;
    }

    .formHeadings {
      font-size: 1.5rem;
    }

    .create-candidates-form-wrapper {
      background: #ffffff;
      padding: 0px 10px;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;


      .create-candidates-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .backgroundStyle {
          border: 1px solid #eeeeee;
          padding: 10px;
          border-radius: 7px;
          background-color: #fbfbfb;
        }

        .formSubSectionHeading {
          font-size: 1rem;
          margin-top: 0;
          font-weight: 600;
          color: #3d3d3d;
        }

        .cstmFormGridEmployer {
          margin-bottom: 1rem;
          padding: 0px 1rem;
        }

        .form-control {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .datePickerGetInput>div>input {
            padding: 8.5px 14px;
            font-size: 0.9rem;
          }

          .radioBtnDiv {
            flex-direction: row;
          }

          .radioBtnDiv>label span:nth-child(even) {
            font-size: 0.9rem;
          }



          .form-label {
            font-weight: 500;
            font-size: 0.9rem;
          }

          .form-input {
            font-size: 0.9rem;
            padding: 10px;
            border: 1px solid #e4e3e3;
            border-radius: 5px;
          }

          .form-error {
            color: #ad3030;
            font-size: 0.87rem;
          }
        }

        .form-button-section {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          box-sizing: border-box;
          // padding: 0px 1rem;

          .form-button {
            width: fit-content;
          }
        }


      }
    }
  }
}

.headingBold {
  font-weight: 600 !important;
}

.backgroundStyle {
  border: 1px solid #eeeeee;
  padding: 10px 15px;
  border-radius: 7px;
  background-color: #fbfbfb;
}

.dflex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.marginTopBottom {
  margin: 1.5rem 0;
}

.css-1h51icj-MuiAutocomplete-root {
  width: 100% !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-im7n24-MuiSvgIcon-root {
  width: 35px;
}

.marginBottom10px {
  margin-bottom: 10px !important;
}

.fontBold {
  font-weight: 600 !important;
}